import React from 'react';
import styled from 'styled-components';

import {ContainerLg, ExternalLinkButton} from '../components';
import {StaticImage} from 'gatsby-plugin-image';
import {breakpointsMediaQueries, COLORS} from '../../style/my-theme';
import {SponsorInfo} from '../content';

const StyledSponsorsSection = styled.section.attrs({
  className: 'sponsors-overview-section'
})`
  margin: 4rem 0;

  .container-lg {
    .section-description {
      line-height: 2rem;
      text-align: center;
      font-family: 'Montserrat', sans-serif;

      h1 {
        color: ${COLORS.primary};
        font-size: 2rem;
        font-weight: 600;
        &.gold {
          font-size: 3rem;
        }
        &.silver {
          font-size: 2rem;
        }
        &.friends {
          font-size: 1.5rem;
        }
      }
      h2 {
        color: ${COLORS.primary};
        font-size: 1.6rem;
        a {
          color: ${COLORS.blueLight};
        }
      }
    }
    .gold-section {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-bottom: 2rem;

      a {
        min-width: 300px;
        text-align: center;
      }

      ${breakpointsMediaQueries.md} {
        display: flex;
        flex-direction: column;

        a {
          text-align: center;
          img {
            max-width: 80%;
            object-fit: contain;
          }
        }
      }
    }
  }
`;

const SponsorsSection = () => {
  return (
    <StyledSponsorsSection>
      <ContainerLg>
        {/* <div className="section-description">
          <h1 className="gold">GOLD</h1>
        </div>
        <div className="gold-section">
          <a href={'https://www.sevenbridges.com/'} target="_blank">
            <img
              src="/images/sponsors-new/sevenbridges-logo.svg"
              alt={'Sevenbridges logo'}
              height={64}
            />
          </a>
          <a href={'https://www.prodyna.com/en/'} target="_blank">
            <img
              src="/images/sponsors-new/prodyna-logo-mono-black.png"
              alt={'Prodyna logo'}
              height={64}
            />
          </a>
        </div> */}

        <div className="section-description">
          <h1 className="silver">SILVER</h1>
        </div>
        <div className="gold-section">
          <a href={'https://www.epam.com/careers/epam-serbia'} target="_blank">
            <img src="/images/sponsors-new/epam-logo.svg" alt={'Epam logo'} height={76} />
          </a>
          <a href={'https://angular.dev/'} target="_blank">
            <img src="/images/sponsors-new/angular-logo.png" alt={'Angular logo'} height={96} />
          </a>
          <a href={'https://www.comdata.rs/'} target="_blank">
            <img src="/images/sponsors-new/comdata-logo.png" alt={'ComData logo'} height={112} />
          </a>
          {/*
          <a href={'https://brame.ch/'} target="_blank">
            <img src="/images/sponsors-new/brame-logo.svg" alt={'Brame logo'} height={152} />
          </a> */}
        </div>
        {/* <div className="gold-section">
          <a href={'https://www.yettel.rs/'} target="_blank">
            <img src="/images/sponsors-new/yettel-logo.svg" alt={'Yettel logo'} height={128} />
          </a>
          <a href={'https://www.bosch.rs/'} target="_blank">
            <img src="/images/sponsors-new/bosch-logo.svg" alt={'Bosch logo'} height={64} />
          </a>
        </div>
        <div className="gold-section">
          <a
            href={'https://location.foursquare.com/company/careers/view-all/#engineering'}
            target="_blank"
          >
            <img src="/images/sponsors-new/fsq-logo.png" alt={'Foursquare logo'} height={78} />
          </a>
          <a href={'https://www.nabis.com/'} target="_blank">
            <img src="/images/sponsors-new/nabis-logo.svg" alt={'Nabis logo'} height={48} />
          </a>
        </div> */}

        <div className="section-description">
          <h1 className="friends">FRIENDS</h1>
        </div>
        <div className="gold-section">
          <a href={'https://oreillymedia.pxf.io/Y9WrWm'} target="_blank">
            <img src="/images/sponsors-new/oreilly-logo.png" alt={'Oreilly logo'} height={96} />
          </a>
          <a href={'https://shiftmag.dev/'} target="_blank">
            <img
              src="/images/sponsors-new/shiftmag-logo.svg"
              alt={'Shift magazine logo'}
              height={52}
            />
          </a>
          <a href={'https://www.netokracija.rs/'} target="_blank">
            <img
              src="/images/sponsors-new/netokracija-logo.png"
              alt={'Netokracija logo'}
              height={48}
            />
          </a>
        </div>
        <div className="gold-section">
          <a href={'https://angularbelgrade.org/'} target="_blank">
            <img
              src="/images/sponsors-new/angular-belgrade-logo.svg"
              alt={'Angular Belgrade logo'}
              height={128}
            />
          </a>
          <a href={'https://www.icthub.rs/'} target="_blank">
            <img src="/images/sponsors-new/ict-hub-logo.svg" alt={'ICT Hub logo'} height={144} />
          </a>
          <a href={'https://dafed.org/'} target="_blank">
            <img src="/images/sponsors-new/dafed-logo.svg" alt={'Dafed logo'} height={128} />
          </a>
        </div>

        <div className="section-description">
          <h2>WANT TO JOIN AS A SPONSOR?</h2>
          <ExternalLinkButton
            label="CONTACT US!"
            url="mailto:contact@armada-js.com"
          ></ExternalLinkButton>
        </div>
      </ContainerLg>
    </StyledSponsorsSection>
  );
};

export default SponsorsSection;
